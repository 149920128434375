import PropTypes from 'prop-types';

import { Button, TextInput } from 'site-react/components/form';
import { LinkAsButton } from 'site-react/components/navigation';
import ModalNew, { LazyModal } from 'site-react/components/page/ModalNew';
import { ActionText, Heading } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import useUser from 'site-react/hooks/useUser';
import { BuildingPropTypes, PricePlanPropTypes } from 'site-react/proptypes';

import HowDoViewingsWork from './components/HowDoViewingsWork';
import OfficeDetail from './components/OfficeDetail';
import styles from './OfficeFinder.module.css';

export default function OfficeFinder({
  building,
  cohort = 'control',
  dispatchSearchState,
  matches,
  searchState,
  submitParams,
}) {
  const pricePlan = matches ? matches[0] : null;
  const otherOptionsCount = Math.max(matches.length - 1, 0);

  const { user } = useUser();

  const quoteModalButtonProps = {
    copy: cohort === 'control' ? 'Get a detailed quote' : 'Enquire',
    name: cohort === 'control' ? 'Get a detailed quote' : 'Enquire',
  };

  return (
    <>
      <Heading type="title4">Interested in this building?</Heading>
      <VerticalSpacing size="md" />
      <TextInput
        aria-label="Team size"
        iconType="person"
        inputMode="numeric"
        labelText="Team size"
        onChange={(event) => {
          dispatchSearchState({ peopleMin: event.target.value });
          submitParams();
        }}
        pattern="[0-9]*"
        placeholder="e.g. 10"
        type="text"
        value={searchState.peopleMin}
      />
      <VerticalSpacing size="md" />
      {pricePlan ? (
        <OfficeDetail
          otherOptionsCount={otherOptionsCount}
          pricePlan={pricePlan}
        />
      ) : (
        <LinkAsButton
          href="#office-details"
          isBlock
          isCompact
          name="View offices"
        >
          View offices
        </LinkAsButton>
      )}
      <VerticalSpacing size="sm" />
      <LazyModal
        id="get-a-quote"
        label="Get a detailed quote"
        lazyComponent={async () =>
          pricePlan?.typeOfPricePlan === 'part-time-office'
            ? (await import('../PartTimeQuoteModal')).default
            : (await import('../FullTimeQuoteModal')).default
        }
        renderTrigger={({ isLoading, openModal }) => (
          <Button
            data-analytics-tag="get-a-quote-office-finder"
            disabled={isLoading}
            isBlock
            isCompact
            isLoading={isLoading}
            name={quoteModalButtonProps.name}
            onClick={openModal}
            styleType="secondary"
            type="button"
          >
            {quoteModalButtonProps.copy}
          </Button>
        )}
      >
        {(GetAQuoteModal) => (
          <GetAQuoteModal
            buildingName={building.name}
            location={building.areas.at(-1).name}
            pricePlanId={pricePlan?.id}
            pricePlanSize={pricePlan?.capacity}
            userEmail={user?.email}
          />
        )}
      </LazyModal>

      <VerticalSpacing size="lg" />

      <ModalNew
        id="how-do-viewings-work"
        label="How do viewings work?"
        renderTrigger={({ openModal }) => (
          <div className={styles['OfficeFinder-howDoViewingsWork']}>
            <ActionText
              isUnderlined
              label="How do viewings work?"
              onClick={openModal}
              styleType="secondary"
            >
              <Heading type="content2">
                <b>How do viewings work?</b>
              </Heading>
            </ActionText>
          </div>
        )}
      >
        <div className={styles['OfficeFinder-howDoViewingsWorkContent']}>
          <HowDoViewingsWork />
        </div>
      </ModalNew>
    </>
  );
}

OfficeFinder.propTypes = {
  building: BuildingPropTypes.isRequired,

  /**
   * Callback to dispatch changes to search state
   */
  dispatchSearchState: PropTypes.func.isRequired,

  matches: PropTypes.arrayOf(PricePlanPropTypes),

  /**
   * Current search parameters
   */
  searchState: PropTypes.shape({
    peopleMin: PropTypes.string,
  }),

  /**
   * Callback to persist search state to URL
   */
  submitParams: PropTypes.func.isRequired,
};
