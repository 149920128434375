import { yupResolver } from '@hookform/resolvers/yup';
import { NetworkError } from '@hubble/request';
import cn from 'classnames';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import SpaciousApi from 'site-react/api/SpaciousApi';
import {
  Button,
  DatePicker,
  TextArea,
  Select,
} from 'site-react/components/form';
import { CloseButton, OutboundLink } from 'site-react/components/navigation';
import { Grid } from 'site-react/components/page';
import { DrawerContext } from 'site-react/components/page/Drawer';
import {
  ErrorMessage,
  Heading,
  Paragraph,
  TextWithIcon,
} from 'site-react/components/typography';
import { ImgixImage } from 'site-react/components/utility';
import {
  ADVISOR_GENERIC_IMAGE_SRC,
  ADVISOR_GENERIC_EMAIL,
  ADVISOR_GENERIC_PHONE_NO,
} from 'site-react/data/advisors';
import {
  getNextAvailableAppointment,
  getTimesForDay,
} from 'site-react/features/ViewingRequest';
import holidays from 'site-react/helpers/holidays';
import logError from 'site-react/helpers/logError';

import styles from './AdditionalDetails.module.css';

const spaciousApi = new SpaciousApi();

export default function AdditionalDetails({
  advisor,
  likedRecommendationListItems,
  onNext,
  setRecommendationList,
  token,
}) {
  const [error, setError] = useState(null);

  const { closeDrawer } = useContext(DrawerContext);

  const minimumAppointmentDate = useMemo(() => {
    const minimumAppointmentISO = getNextAvailableAppointment(
      DateTime.utc(),
      'Europe/London',
      '8:30',
      '18:00',
      { viewingType: 'physical' },
    );

    return DateTime.fromISO(minimumAppointmentISO, {
      setZone: true,
    });
  }, []);

  const maximumAppointmentDate = minimumAppointmentDate.plus({ days: 28 });

  const validationSchema = Yup.object().shape({
    additionalInformation: Yup.string(),
    startTime: Yup.string().required('Please select a time'),
    viewingDate: Yup.date()
      .typeError('Please select a date')
      .required('Please select a date')
      .test(
        'is-weekday',
        'Must be a weekday',
        (value) => DateTime.fromJSDate(value).weekday < 6,
      )
      .test(
        'is-holiday',
        ({ value }) =>
          `${DateTime.fromJSDate(value).toLocaleString(DateTime.DATE_FULL)} is a holiday`,
        (value) => {
          const isoDate = DateTime.fromJSDate(value).toISODate();
          return !holidays.includes(isoDate);
        },
      )
      .min(
        minimumAppointmentDate.toISODate(),
        `Viewings cannot be booked before ${minimumAppointmentDate.toLocaleString(
          DateTime.DATE_FULL,
        )}`,
      )
      .max(
        maximumAppointmentDate.toISODate(),
        `Viewings cannot be booked after ${maximumAppointmentDate.toLocaleString(
          DateTime.DATE_FULL,
        )}`,
      ),
  });

  const { formState, handleSubmit, register, setValue, trigger, watch } =
    useForm({
      defaultValues: {
        additionalInformation: '',
        startTime: minimumAppointmentDate.toFormat('HH:mm') ?? '8:30',
        viewingDate: minimumAppointmentDate.toISODate(),
      },
      mode: 'onBlur',
      resolver: yupResolver(validationSchema),
    });

  const values = watch();

  const times = useMemo(() => {
    return getTimesForDay(values.viewingDate, {
      buildingCloseTime: '18:00',
      buildingOpenTime: '8:30',
      viewingType: 'physical',
    });
  }, [values.viewingDate]);

  useEffect(() => {
    /**
     * If when changing dates the previously selected time is now disabled, select the first available time
     */
    if (times.find((time) => time.time === values.startTime)?.disabled) {
      const firstAvailableTime = times.find((time) => !time.disabled);
      if (firstAvailableTime) {
        setValue('startTime', firstAvailableTime.time);
      }
    }
  }, [values.startTime, setValue, times]);

  async function onSubmit(data) {
    setError(null);

    try {
      await spaciousApi.createRecommendationsRequestViewings(token, {
        additionalInformation: data.additionalInformation,
        recommendationListItems: likedRecommendationListItems.map(
          (item) => item.id,
        ),
        startTime: data.startTime,
        viewingDate: DateTime.fromJSDate(data.viewingDate).toFormat(
          'yyyy-MM-dd',
        ),
      });

      setRecommendationList((prevRecommendationList) => {
        const updatedRecommendationList = {
          ...prevRecommendationList,
          items: prevRecommendationList.items.map((recommendationListItem) => {
            if (
              likedRecommendationListItems.some(
                (item) => item.id === recommendationListItem.id,
              )
            ) {
              return {
                ...recommendationListItem,
                status: 'viewing requested',
              };
            }

            return recommendationListItem;
          }),
        };

        return updatedRecommendationList;
      });

      onNext();
    } catch (error) {
      setError('There was a problem with your request. Please try again.');

      if (error instanceof NetworkError) {
        logError(error);
      }
    }
  }

  return (
    <div className={styles.AdditionalDetails}>
      <div className={styles['AdditionalDetails-heading']}>
        <Heading level="1" type="title3">
          Just a few more details
        </Heading>
        <CloseButton onClick={closeDrawer} />
      </div>

      <div
        className={cn(styles['AdditionalDetails-advisor'], {
          [styles['is-genericAdvisor']]: !advisor,
        })}
      >
        <ImgixImage
          alt="Advisor"
          height={80}
          src={advisor?.image ?? ADVISOR_GENERIC_IMAGE_SRC}
          width={advisor?.image ? 80 : 217}
        />
        <Paragraph isMarginless>
          This information will help {advisor?.name ?? 'your advisor'} organise
          your viewing schedule.
        </Paragraph>
      </div>

      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid.Item colSpan="6">
            <DatePicker
              disabled={[
                ...holidays.map((holiday) => {
                  return new Date(holiday);
                }),
                {
                  after: maximumAppointmentDate.toJSDate(),
                  before: minimumAppointmentDate.toJSDate(),
                },
                { dayOfWeek: [0, 6] },
              ]}
              errorText={formState.errors.viewingDate?.message}
              labelText="Viewing date"
              name="viewingDate"
              onDateSelect={(date) => {
                setValue(
                  'viewingDate',
                  DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'),
                );
                trigger('viewingDate');
              }}
              placeholder="dd/mm/yyyy"
              selectedDate={
                DateTime.fromISO(values.viewingDate).toJSDate().toString() ===
                'Invalid Date'
                  ? null
                  : DateTime.fromISO(values.viewingDate).toJSDate()
              }
              status={formState.errors.viewingDate?.message && 'error'}
            />
          </Grid.Item>

          <Grid.Item colSpan="6">
            <Select
              data-testid="startTime"
              labelText="Time of first viewing"
              name="startTime"
              {...register('startTime')}
            >
              {times.map((time) => (
                <option
                  disabled={time.disabled}
                  key={time.time}
                  value={time.time}
                >
                  {time.time}
                </option>
              ))}
            </Select>
          </Grid.Item>

          <Grid.Item colSpan="12">
            <TextArea
              labelText="Any additional information (optional)"
              name="additionalInformation"
              placeholder="E.g. where you would like to start your viewings"
              {...register('additionalInformation')}
            />
          </Grid.Item>

          <Grid.Item colSpan="12" justify="center">
            <Button
              disabled={formState.isSubmitting}
              isLoading={formState.isSubmitting}
              name="Send viewing requests to advisor"
              type="submit"
            >
              Send to {advisor?.name?.split(' ')[0] ?? 'your advisor'}
            </Button>
          </Grid.Item>

          {error && (
            <Grid.Item colSpan="12" justify="center">
              <ErrorMessage isMarginless>{error}</ErrorMessage>
            </Grid.Item>
          )}

          <Grid.Item colSpan="12" justify="center">
            <Paragraph isCentered isMarginless>
              Or just get in touch
            </Paragraph>

            <div className={styles['AdditionalDetails-advisorDetails']}>
              <OutboundLink
                href={`tel:${advisor?.tel ?? ADVISOR_GENERIC_PHONE_NO}`}
              >
                <TextWithIcon
                  iconColor="neutral-900"
                  iconName="call"
                  iconPosition="left"
                  text={advisor?.tel ?? ADVISOR_GENERIC_PHONE_NO}
                />
              </OutboundLink>

              <OutboundLink
                href={`mailto:${advisor?.email ?? ADVISOR_GENERIC_EMAIL}`}
              >
                <TextWithIcon
                  iconColor="neutral-900"
                  iconName="mail"
                  iconPosition="left"
                  text={advisor?.email ?? ADVISOR_GENERIC_EMAIL}
                />
              </OutboundLink>
            </div>
          </Grid.Item>
        </Grid>
      </form>
    </div>
  );
}

AdditionalDetails.propTypes = {
  advisor: PropTypes.shape({
    email: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    linkedin: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tel: PropTypes.string.isRequired,
  }),
  likedRecommendationListItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  onNext: PropTypes.func.isRequired,
  setRecommendationList: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};
