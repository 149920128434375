import PropTypes from 'prop-types';

import { Grid, UICard } from 'site-react/components/page';
import { VerticalSpacing } from 'site-react/components/utility';
import { BuildingPropTypes } from 'site-react/proptypes';

import styles from './Hero.module.css';
import AdvisorPromotionCard from '../AdvisorPromotionCard';
import HqActions from '../HqActions';
import HqListingUspSection from '../HqListingUspSection';
import ImageCarousel from '../ImageCarousel';

export default function Hero({
  building,
  cohort = 'control',
  dispatchSearchState,
  matches,
  officeSelectorABCohort,
  searchState,
  submitParams,
}) {
  return (
    <Grid breakpoint="lg" gridGapY="md">
      <Grid.Item colSpan="8" rowSpan="2">
        <ImageCarousel
          building={building}
          desktopRatio="2:1"
          mobileRatio="2:1"
          overrideAspectRatio
        />
        <VerticalSpacing size="lg" />
        <HqListingUspSection buildingType={building.buildingType} />
      </Grid.Item>
      <Grid.Item colSpan="4" colStart="9">
        <div className={styles['u-hideBelowLg']}>
          <UICard
            boxShadow="resting"
            style={{
              border: 'none',
              padding: 'var(--space-lg)',
            }}
          >
            <HqActions
              building={building}
              cohort={cohort}
              dispatchSearchState={dispatchSearchState}
              matches={matches}
              officeSelectorABCohort={officeSelectorABCohort}
              searchState={searchState}
              submitParams={submitParams}
            />
          </UICard>
        </div>
      </Grid.Item>
      <Grid.Item colSpan="4" colStart="9">
        <div className={styles['u-hideBelowLg']}>
          <UICard
            boxShadow="resting"
            style={{
              border: 'none',
              padding: 'var(--space-lg)',
            }}
          >
            <AdvisorPromotionCard />
          </UICard>
        </div>
      </Grid.Item>
    </Grid>
  );
}

Hero.propTypes = {
  building: BuildingPropTypes.isRequired,
  officeSelectorABCohort: PropTypes.oneOf(['control', 'variant']).isRequired,
};
