import { useContext } from 'react';

import { whatsapp } from 'site-react/assets';
import { CloseButton, OutboundLink } from 'site-react/components/navigation';
import { Grid, UICard, UICardLink } from 'site-react/components/page';
import { ModalNewContext } from 'site-react/components/page/ModalNew';
import {
  Heading,
  ListItemWithIcon,
  Paragraph,
  TextWithIcon,
} from 'site-react/components/typography';
import {
  Asset,
  Centered,
  ImgixImage,
  UnstyledList,
  VerticalSpacing,
} from 'site-react/components/utility';
import advisors from 'site-react/data/advisors';

import styles from './HowDoViewingsWork.module.css';

export default function HowDoViewingsWork() {
  const { closeModal } = useContext(ModalNewContext);

  return (
    <Grid>
      <Grid.Item colSpan="7" rowStart="1">
        <Heading level="1" type="title3">
          Booking a viewing is easy
        </Heading>
      </Grid.Item>

      <Grid.Item colSpan="7" colStart="1" rowSpan="2" rowStart="2">
        <UnstyledList>
          <ListItemWithIcon
            heading="Instant confirmation:"
            iconName="bolt"
            text="Pick a date and time for your viewing, and we’ll send a confirmation email and calendar invite to you and the office provider right away."
          />
          <ListItemWithIcon
            heading="Easy rescheduling:"
            iconName="calendar_today"
            text="On the rare occasion that a provider can’t make the viewing or you need to change the time, we’ll handle the rescheduling for you."
          />
          <ListItemWithIcon
            heading="Your 30-minute tour:"
            iconName="visibility"
            text="The office provider will show you the building, the office you’re interested in, and any other suitable options."
          />
        </UnstyledList>
      </Grid.Item>

      <Grid.Item colSpan="5" colStart="8" justify="end">
        <CloseButton onClick={closeModal} />
      </Grid.Item>

      <Grid.Item colSpan="5" colStart="8">
        <UICard style={{ padding: 'var(--space-lg)' }}>
          <Heading level="2" type="title4">
            Let Fleur handle your viewings
          </Heading>

          <VerticalSpacing size="sm" />

          <div className={styles['HowDoViewingsWork-advisor']}>
            <ImgixImage
              alt="Fleur"
              height={60}
              src={advisors[96669].image}
              width={60}
            />

            <div className={styles['HowDoViewingsWork-advisorContent']}>
              <OutboundLink href="tel:020 4587 6070">
                <TextWithIcon
                  contentType="title4"
                  iconColor="neutral-900"
                  iconName="call"
                  iconPosition="left"
                  text={<b>020 4587 6070</b>}
                />
              </OutboundLink>

              <VerticalSpacing size="xs" />

              <div className={styles['HowDoViewingsWork-advisorContact']}>
                <UICardLink
                  href="mailto:advisors@hubblehq.com?subject=New Office Enquiry - Ref: OF-P9125"
                  linkType="OutboundLink"
                  name="Email"
                  style={{
                    borderRadius: 'var(--space-xs)',
                    padding: 'var(--space-sm)',
                  }}
                  width="shrink-wrap"
                >
                  <TextWithIcon
                    iconName="email"
                    iconPosition="left"
                    text="Email"
                  />
                </UICardLink>
                <UICardLink
                  href="https://api.whatsapp.com/message/JS74MBMJQ757K1?autoload=1&app_absent=0"
                  linkType="OutboundLink"
                  name="Whatsapp"
                  style={{
                    borderRadius: 'var(--space-xs)',
                    padding: 'var(--space-sm)',
                  }}
                  target="_blank"
                  width="shrink-wrap"
                >
                  <div className={styles['HowDoViewingsWork-whatsapp']}>
                    <Asset
                      alt="Whatsapp"
                      height="32px"
                      src={whatsapp}
                      width="32px"
                    />
                    <span>Whatsapp</span>
                  </div>
                </UICardLink>
              </div>
            </div>
          </div>

          <VerticalSpacing size="md" />

          <Paragraph>
            Get in touch and I’ll be happy to arrange all of your viewings for
            you
          </Paragraph>
        </UICard>
      </Grid.Item>
      <Grid.Item colSpan="5" colStart="8" justify="center">
        <Centered>
          <OutboundLink
            href="https://www.trustpilot.com/review/hubblehq.com"
            target="_blank"
          >
            <ImgixImage
              alt="Trustpilot five star score"
              height={14}
              src="/site/homepage/trustpilot-black-letters.svg"
              width={180}
            />
          </OutboundLink>
        </Centered>

        <VerticalSpacing size="sm" />

        <Centered>
          <q className={styles['HowDoViewingsWork-quote']}>
            Really responsive, available on the phone whenever I needed, sorted
            everything out and we managed to find a new office space within a
            fortnight.
          </q>
          <cite className={styles['HowDoViewingsWork-author']}> - Rhona</cite>
        </Centered>
      </Grid.Item>
    </Grid>
  );
}
