import cn from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { Button } from 'site-react/components/form';
import Drawer from 'site-react/components/page/Drawer';
import buttonStyles from 'site-react/components/styles/button.module.css';
import { TextWithIcon } from 'site-react/components/typography';

import styles from './Actions.module.css';
import AdditionalDetails from '../../../../../../../AdditionalDetails';
import Success from '../../../../../../../Success';
import ViewingBasket from '../../../../../../../ViewingBasket';

const ViewingBasketPage = {
  Basket: 'basket',
  Details: 'details',
  Success: 'success',
};

export default function Actions({
  advisor,
  handleLike,
  handleReject,
  handleRestore,
  recommendationListItem,
  setRecommendationList,
  token,
  likedRecommendationListItems,
  viewingRequestedItems,
}) {
  const [page, setPage] = useState(ViewingBasketPage.Basket);

  if (recommendationListItem.status !== 'rejected') {
    return (
      <div className={styles.Actions}>
        <Button
          data-analytics-tag="recs_not_interested"
          data-testid="not-interested"
          isCompact
          name="Not interested"
          onClick={handleReject}
          styleType="secondary"
          type="button"
        >
          <TextWithIcon
            iconName="thumb_down"
            iconPosition="left"
            text="Not interested"
          />
        </Button>
        <Drawer
          label="Shedule a viewing"
          onCloseCallback={() => {
            setPage(ViewingBasketPage.Basket);
          }}
          renderTrigger={({ openDrawer }) => {
            return (
              <button
                className={cn(
                  buttonStyles.Button,
                  buttonStyles['Button--white'],
                  buttonStyles['Button--compact'],
                  styles['Actions-scheduleAViewing'],
                  {
                    [cn(buttonStyles['Button--primary'], styles['is-liked'])]:
                      recommendationListItem.status === 'liked',
                  },
                )}
                data-analytics-tag="recs_schedule_viewing"
                data-testid="schedule-viewing"
                disabled={recommendationListItem.status === 'liked'}
                onClick={() => {
                  handleLike();
                  openDrawer();
                }}
                type="button"
              >
                <TextWithIcon
                  iconName={
                    recommendationListItem.status === 'liked'
                      ? 'check_circle'
                      : 'thumb_up'
                  }
                  iconPosition="left"
                  text={
                    recommendationListItem.status === 'liked'
                      ? 'Added to schedule'
                      : 'Schedule a viewing'
                  }
                />
              </button>
            );
          }}
        >
          <div className={styles['Actions-drawer']}>
            {page === ViewingBasketPage.Basket && (
              <ViewingBasket
                advisor={advisor}
                likedRecommendationListItems={likedRecommendationListItems}
                onNext={() => setPage(ViewingBasketPage.Details)}
                setRecommendationList={setRecommendationList}
                token={token}
                viewingRequestedItems={viewingRequestedItems}
              />
            )}
            {page === ViewingBasketPage.Details && (
              <AdditionalDetails
                advisor={advisor}
                likedRecommendationListItems={likedRecommendationListItems}
                onNext={() => setPage(ViewingBasketPage.Success)}
                setRecommendationList={setRecommendationList}
                token={token}
                viewingRequestedItems={viewingRequestedItems}
              />
            )}
            {page === ViewingBasketPage.Success && (
              <Success
                advisor={advisor}
                viewingRequestedItems={viewingRequestedItems}
              />
            )}
          </div>
        </Drawer>
      </div>
    );
  } else {
    return (
      <div>
        <Button
          data-analytics-tag="recs_restore"
          data-testid="restore"
          isBlock
          isCompact
          name="Bring back"
          onClick={handleRestore}
          styleType="secondary"
          type="button"
        >
          <TextWithIcon
            iconName="settings_backup_restore"
            iconPosition="left"
            text="Bring back"
          />
        </Button>
      </div>
    );
  }
}

Actions.propTypes = {
  advisor: PropTypes.shape({
    email: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    linkedin: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tel: PropTypes.string.isRequired,
  }),
  handleLike: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
  handleRestore: PropTypes.func.isRequired,
  likedRecommendationListItems: PropTypes.arrayOf(
    PropTypes.shape({
      advisorNotes: PropTypes.string,
      createdAt: PropTypes.string.isRequired,
      customerNotes: PropTypes.string,
      id: PropTypes.number.isRequired,
      isPartTime: PropTypes.bool,
      likedAt: PropTypes.string,
      pricePlanId: PropTypes.number.isRequired,
      recommendationList: PropTypes.number.isRequired,
      rejectedAt: PropTypes.string,
      status: PropTypes.oneOf([
        'liked',
        'pending',
        'viewing requested',
        'rejected',
      ]),
      updatedAt: PropTypes.string,
      viewingRequestedAt: PropTypes.string,
    }),
  ),
  recommendationListItem: PropTypes.shape({}).isRequired,
  setRecommendationList: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  viewingRequestedItems: PropTypes.arrayOf(
    PropTypes.shape({
      advisorNotes: PropTypes.string,
      createdAt: PropTypes.string.isRequired,
      customerNotes: PropTypes.string,
      id: PropTypes.number.isRequired,
      isPartTime: PropTypes.bool,
      likedAt: PropTypes.string,
      pricePlanId: PropTypes.number.isRequired,
      recommendationList: PropTypes.number.isRequired,
      rejectedAt: PropTypes.string,
      status: PropTypes.oneOf([
        'liked',
        'pending',
        'viewing requested',
        'rejected',
      ]),
      updatedAt: PropTypes.string,
      viewingRequestedAt: PropTypes.string,
    }),
  ),
};
