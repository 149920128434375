import React from 'react';

import { whatsapp } from 'site-react/assets';
import { OutboundLink } from 'site-react/components/navigation';
import { UICardLink } from 'site-react/components/page';
import {
  Heading,
  Paragraph,
  TextWithIcon,
} from 'site-react/components/typography';
import {
  Asset,
  ImgixImage,
  VerticalSpacing,
} from 'site-react/components/utility';
import advisors from 'site-react/data/advisors';

import styles from './AdvisorPromotionCard.module.css';

export default function AdvisorPromotionCard() {
  return (
    <>
      <Heading level="2" type="title4">
        Connect with Fleur now
      </Heading>

      <VerticalSpacing size="sm" />

      <div className={styles['AdvisorPromotionCard-advisor']}>
        <ImgixImage
          alt="Fleur"
          height={60}
          src={advisors[96669].image}
          width={60}
        />

        <div className={styles['AdvisorPromotionCard-advisorContent']}>
          <OutboundLink href="tel:020 4587 6070">
            <TextWithIcon
              contentType="title4"
              iconColor="neutral-900"
              iconName="call"
              iconPosition="left"
              text={<b>020 4587 6070</b>}
            />
          </OutboundLink>

          <VerticalSpacing size="xs" />

          <div className={styles['AdvisorPromotionCard-advisorContact']}>
            <UICardLink
              href="mailto:advisors@hubblehq.com?subject=New Office Enquiry - Ref: OF-P9125"
              linkType="OutboundLink"
              name="Email"
              style={{
                borderRadius: 'var(--space-xs)',
                padding: 'var(--space-sm)',
              }}
              width="shrink-wrap"
            >
              <TextWithIcon iconName="email" iconPosition="left" text="Email" />
            </UICardLink>
            <UICardLink
              href="https://api.whatsapp.com/message/JS74MBMJQ757K1?autoload=1&app_absent=0"
              linkType="OutboundLink"
              name="Whatsapp"
              style={{
                borderRadius: 'var(--space-xs)',
                padding: 'var(--space-sm)',
              }}
              target="_blank"
              width="shrink-wrap"
            >
              <div className={styles['AdvisorPromotionCard-whatsapp']}>
                <Asset
                  alt="Whatsapp"
                  height="32px"
                  src={whatsapp}
                  width="32px"
                />
                <span>Whatsapp</span>
              </div>
            </UICardLink>
          </div>
        </div>
      </div>

      <VerticalSpacing size="md" />

      <Paragraph>
        I can arrange viewings for you, negotiate the best price and answer any
        of your questions
      </Paragraph>

      <VerticalSpacing size="md" />

      <OutboundLink
        href="https://www.trustpilot.com/review/hubblehq.com"
        target="_blank"
      >
        <ImgixImage
          alt="Trustpilot five star score"
          height={14}
          src="/site/homepage/trustpilot-black-letters.svg"
          width={180}
        />
      </OutboundLink>
    </>
  );
}
