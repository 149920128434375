export default function toBuildingTime(
  buildingTimezone = 'Europe/London',
  dateTime,
  keepLocalTime = false,
) {
  // Note the keepLocalTime parameter.
  //
  // In general, we _won't_ keepLocalTime if we're converting from the user's local
  // time to the building's timezone time. But we _will_ keep local time when we've derived a specific
  // DateTime within this file - which means if you start from Saturday, and Sunday has
  // a DST change, then specifying Monday at 8:30 will _always_ be Monday at 8:30 in the building's timezone,
  //
  // Be wary of this functionality.
  return dateTime.setZone(buildingTimezone, { keepLocalTime });
}
