import { DateTime } from 'luxon';

import holidays from 'site-react/helpers/holidays';

import toBuildingTime from './toBuildingTime';

// THE GENERAL LOGIC
//
// This file will, given the current time (regardless of timezone), return the next available
// appointment time for a viewing request.
//
// If we're in the middle of a working day, the logic is simple: return the next London time
// that is at least one hour from now, rounded to the nearest half hour.
//
// We skip weekends and holidays, pushing ahead to the nearest working day at open time.
//
// If it's 1 hour to closing time, we push ahead to the next working day at open time.

const getNextWorkingDay = (
  timezone = 'Europe/London',
  dateTime,
  buildingCloseTime,
  offset = 0,
) => {
  let nextWorkingDay = DateTime.fromISO(dateTime, {
    setZone: true,
  }).plus({ hours: offset });

  const [closingHour, closingMinute] = buildingCloseTime.split(':');
  const closingDateTime = toBuildingTime(
    timezone,
    DateTime.fromISO(dateTime).set({
      hour: closingHour,
      minute: closingMinute,
    }),
    true,
  );

  // Check if it's past the building's close time. If it is, then today isn't a working day anymore.
  if (closingDateTime.plus({ hour: -1 + offset }) <= nextWorkingDay) {
    nextWorkingDay = nextWorkingDay.plus({ days: 1 });
  }

  while (
    [6, 7].includes(nextWorkingDay.weekday) ||
    holidays.includes(nextWorkingDay.toISODate())
  ) {
    nextWorkingDay = nextWorkingDay.plus({ days: 1 });
  }

  return nextWorkingDay;
};

const getNextAvailableAppointment = (
  from,
  timezone,
  buildingOpenTime,
  buildingCloseTime,
) => {
  // Adjust the 'from' time zone to UTC.
  const now = toBuildingTime(timezone, DateTime.fromISO(from).toUTC(), false);
  let nextAppointment = getNextWorkingDay(timezone, now, buildingCloseTime, 0);

  const [buildingOpenHour, buildingOpenMinute] = buildingOpenTime.split(':');

  const buildingOpenDateTime = now.set({
    hours: buildingOpenHour,
    minutes: buildingOpenMinute,
  });

  if (nextAppointment.get('hour') !== 23) {
    nextAppointment = nextAppointment.plus({ hour: 1 });
  }

  // This will always round _up_ to the nearest 15 minute interval.
  const remainder = nextAppointment.minute % 15;
  nextAppointment = nextAppointment.plus({ minute: 15 - remainder });

  const isWorkingDayStarted = now >= buildingOpenDateTime;

  if (nextAppointment.toISODate() !== now.toISODate() || !isWorkingDayStarted) {
    // If the next working day isn't today, then the next appointment is at building open time plus 1 hour
    // Or, if the current working day hasn't begun, apply the same logic: building open time plus 1 hour
    nextAppointment = nextAppointment.set({
      hour: parseInt(buildingOpenHour) + 1,
      minute: parseInt(buildingOpenMinute),
    });
  }

  // Hard-set the nextAppointment to a local London time. Doesn't _adjust_ the time, just
  // sets the time zone to reflect British Summer Time and keeps the actual hours/minutes
  // the same.
  //
  // Set to start of the current minute, to avoid rounding errors in date comparisons.
  return toBuildingTime(timezone, nextAppointment, true)
    .startOf('minute')
    .toISO();
};

export default getNextAvailableAppointment;
