import PropTypes from 'prop-types';
import React from 'react';

import { UICard } from 'site-react/components/page';
import HoppyContainer from 'site-react/data/listing/HoppyContainer';
import { BuildingPropTypes, PricePlanPropTypes } from 'site-react/proptypes';

import ArchivedBanner from './ArchivedBanner';
import OfficeFinder from '../OfficeFinder';

const HqActions = ({
  building,
  cohort = 'control',
  dispatchSearchState = () => {},
  matches = [],
  officeSelectorABCohort, // We will use this to handle which version of the office picker we render
  searchState = {},
  submitParams = () => {},
}) => {
  if (building.status === 'archived' || building.pricePlans.length === 0) {
    return (
      <UICard boxShadow="resting" style={{ border: 'none' }}>
        <ArchivedBanner building={building} />
      </UICard>
    );
  }

  return (
    <>
      <OfficeFinder
        building={building}
        cohort={cohort}
        dispatchSearchState={dispatchSearchState}
        matches={matches}
        searchState={searchState}
        submitParams={submitParams}
      />

      {building.status !== 'archived' ? (
        <HoppyContainer building={building}>
          {({ hoppy }) => (
            <script
              dangerouslySetInnerHTML={{
                __html: JSON.stringify({
                  ...hoppy,
                }),
              }}
              id="hoppy-data"
              type="application/json"
            />
          )}
        </HoppyContainer>
      ) : null}
    </>
  );
};

HqActions.propTypes = {
  building: BuildingPropTypes.isRequired,
  dispatchSearchState: PropTypes.func,
  matches: PropTypes.arrayOf(PricePlanPropTypes),
  officeSelectorABCohort: PropTypes.oneOf(['control', 'variant']).isRequired,
  searchState: PropTypes.shape({}),
  submitParams: PropTypes.func,
};

export default HqActions;
